import React from "react"

import TransError from "../components/shared/TransError"
import PageContent from "../components/shared/PageContent"
import { TLDR } from "../components/shared/Typography"
import SEO from "../components/SEO"

const TermsPage = () => (
  <>
    <TransError />
    <SEO title="Terms of Use" />
    <PageContent>
      <h1>Terms of Use</h1>
      <TLDR>
        <span>TLDR</span> Just go travel and have fun!
      </TLDR>
      <p>
        <strong>July 2, 2019</strong> - Working on a formal version of these
        terms.
      </p>
      <p>
        {" "}
        Until then, you're free to use this site in any way you would like. A
        social media shoutout would be much appreciated if you decide to share
        this app or use any of its information for your own projects.
      </p>
    </PageContent>
  </>
)

export default TermsPage
